import React from 'react'
import cx from 'classnames'
import styles from './ContactDetails.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'

export default function ContactDetails({ className }) {
  return (
    <section className={cx(className, styles.container)}>
      <h2>
        <FontAwesomeIcon icon={faLeaf} className="leafIcon" />
        Yhteystiedot
      </h2>
      <h3>Hääpari</h3>
      <div className={styles.wrapper}>
        <p className={styles.person}>
          Vesa
          <br />
          <a href="tel:+358000000000">000 000 0000</a>
          <br />
          <a href="mailto:vesa@example.com">vesa@example.com</a>
        </p>
        <p className={styles.person}>
          Jenni
          <br />
          <a href="tel:+358000000000">000 000 0000</a>
          <br />
          <a href="mailto:jenni@example.com">jenni@example.com</a>
        </p>
      </div>
      <h3>Kaasot & Bestmanit</h3>
      <div className={styles.wrapper}>
        <p className={styles.person}>
          Hanna
          <br />
          <a href="tel:+358000000000">000 000 0000</a>
          <br />
          <a href="mailto:hanna@example.com">
            hanna@example.com
          </a>
        </p>
        <p className={styles.person}>
          Carita
          <br />
          <a href="tel:+358000000000">000 000 0000</a>
          <br />
          <a href="mailto:carita@example.com">carita@example.com</a>
        </p>
        <p className={styles.person}>
          Tomi
          <br />
          <a href="tel:+358000000000">000 000 0000</a>
          <br />
          <a href="mailto:tomi@example.com">tomi@example.com</a>
        </p>
        <p className={styles.person}>
          Eevert
          <br />
          <a href="tel:+358000000000">000 000 0000</a>
          <br />
          <a href="mailto:eevert@example.com">
            eevert@example.com
          </a>
        </p>
      </div>
    </section>
  )
}
