import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Heading from '../components/Heading'
import Registration from '../components/Registration'
import PreparingForParty from '../components/PreparingForParty'
import OnTheDay from '../components/OnTheDay'
import ContactDetails from '../components/ContactDetails'
import EasterEggTip from '../components/EasterEggTip'

import styles from './index.module.scss'

const Footer = () => (
  <footer className="grid-margin-x">
    <blockquote>
      "Rakastaa ei ole sama kuin katsoa toisiaan silmiin, se on katsomista
      yhdessä samaan suuntaan."
      <cite>Antoine de Saint-Exupéry</cite>
    </blockquote>
    <EasterEggTip className={styles.easterEggTip} />
  </footer>
)

const IndexPage = () => (
  <Layout>
    <SEO title="J&V 25.5.2019" keywords={[`gatsby`, `application`, `react`]} />

    <Heading className={styles.heading} />
    <Registration className={styles.registration} />
    <PreparingForParty className={styles.preparingForParty} />
    <OnTheDay className={styles.onTheDay} />
    <ContactDetails className={styles.contactDetails} />
    <Footer />
  </Layout>
)

export default IndexPage
