import React from 'react'
import cx from 'classnames'

import Img from './image'
import styles from './Heading.module.scss'

export default function Heading({ className }) {
  return (
    <header className={cx(className, styles.wrapper)}>
      <Img />
      <h1 className={styles.textContainer}>
        <div className={styles.firstName}>Jenni</div>
        <div className={styles.ampersand}>&</div>
        <div className={styles.lastName}>Vesa</div>
      </h1>
    </header>
  )
}
