import React from 'react'
import cx from 'classnames'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'

import styles from './OnTheDay.module.scss'

export default function OnTheDay({ className }) {
  return (
    <section className={cx(className)}>
      <h2>
        <FontAwesomeIcon icon={faLeaf} className="leafIcon" />
        Juhlapäivänä
      </h2>
      <h3>Juhlapaikka</h3>
      <p>
        Vihkiminen ja itse juhla vietetään Villa Åkerblomissa Espoossa
        Laajalahden rannalla ihanassa villassa. Juhlapaikan lähelle voi
        parkkeerata tien varteen ja lähimmältä bussipysäkiltä on noin kilometrin
        kävelymatka.
      </p>
      <p>
        Tilan osoite on{' '}
        <a href="https://goo.gl/maps/pXULzxhm4cH2">
          Villa Åkerblom, Ville Vallgrenin tie 1, Espoo
        </a>
        .
      </p>
      <div className={styles.venueImage}>
        <VenueImage />
      </div>
      <h3>Juhlan kulku</h3>
      <p>
        Vihkiminen tapahtuu juhlapaikalla Villa Åkerblomilla klo 14:00, sään
        salliessa ulkona. Tämän jälkeen siirrymme sisälle syömään ja nauttimaan
        ohjelmasta.
      </p>
      <p>
        Häidemme iltaosuus alkaa klo 19 Villa Åkerblomilla. Valmistaudu
        tanssimaan ja pitämään hauskaa! Sään salliessa myös piha-alue on
        käytössämme. Illan pimetessä tarjolla on murkinaa.
      </p>
      <h3>Jatkot</h3>
      <p>
        Villa Åkerblomilla juhlimme klo 24 asti, jonka jälkeen halukkaille on
        tarjolla kuljetus jatkopaikalle.
      </p>
      <p>
        Jatkopaikka sijaitsee Leppävaarassa hyvien kulkuyhteyksien varrella.
      </p>
    </section>
  )
}

const VenueImage = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "villa-akerblom.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
  />
)
