import React from 'react'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'

export default function PreparingForParty({ className }) {
  return (
    <section className={cx(className)}>
      <h2>
        <FontAwesomeIcon icon={faLeaf} className="leafIcon" />
        Juhliin valmistautuminen
      </h2>
      <h3>Pukukoodi</h3>
      <p>
        Toivomme juhliin pukeuduttavan rennosti mutta keväisen juhlavasti ja
        säätilan huomioon ottaen.
      </p>
      <h3>Lahjatoiveet</h3>
      <p>
        Tärkeintä meille on, että tulette juhlimaan kanssamme. Mikäli kuitenkin
        haluatte meitä muistaa, tässä muutama ehdotus:
      </p>
      <p>
        Häämatkamme menoliput meillä jo on, mutta halutessanne voitte auttaa
        paluulippujemme hankinnassa muistamalla meitä häätilillemme: FI95 1012
        3500 3940 18
      </p>
      <p>
        Aineettomat lahjat erityisesti kulttuurin, elämysten ja hyvän ruoan
        merkeissä ovat aina iloinen yllätys – ja sitä parempi jos lupaudutte
        seuraksi!
      </p>
      <p>
        Mikäli haluaisitte muistaa meitä muunlaisilla lahjoilla, voitte olla
        hääpariin siitä erikseen yhteydessä.
      </p>
      <h3>Puheet, ohjelmat tai yllätykset</h3>
      <p>
        Jos haluatte yllättää hääparin tai järjestää muuta ohjelmaa, voitte
        kertoa tästä ilmoittautumisen yhteydessä tai ottaa yhteyttä kaasoihin
        tai bestmaneihin.
      </p>
    </section>
  )
}
