import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const allSelfies = graphql`
  query {
    selfies: allFile(
      filter: { relativeDirectory: { eq: "selfies" } }
      sort: { fields: [base] }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 650) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const Image = ({ imgIndex, ...otherProps }) => (
  <StaticQuery
    query={allSelfies}
    render={data => {
      const selfie =
        data.selfies.edges[imgIndex || 0].node.childImageSharp.fluid
      return <Img fluid={selfie} {...otherProps} />
    }}
  />
)

const PreloadImage = ({ imgIndex }) =>
  typeof document === 'undefined' ? null : (
    <Image
      imgIndex={imgIndex}
      style={{ position: 'absolute', top: 0, width: '100%', opacity: 0.01 }}
      critical
    />
  )

const imageCount = 15
const firstImageIndex = 5

let useableIndices = new Array(imageCount).fill(0).map((_, idx) => idx)
const randomizedOrder = new Array(imageCount).fill(0).map(() => {
  const randomIndex = Math.floor(Math.random() * useableIndices.length)
  const [randomImgIndex] = useableIndices.splice(randomIndex, 1)
  return randomImgIndex
})

randomizedOrder.splice(
  randomizedOrder.findIndex(el => el === firstImageIndex),
  1
)
randomizedOrder.unshift(firstImageIndex)

function getNextIndex(currIdx) {
  return (currIdx + 1) % imageCount
}

const CycleableImage = () => {
  const [idx, setIndex] = useState(0)
  const imgIndex = randomizedOrder[idx]

  return (
    <>
      <PreloadImage imgIndex={randomizedOrder[getNextIndex(idx)]} />
      <div onClick={() => setIndex(getNextIndex)}>
        <Image imgIndex={imgIndex} />
      </div>
    </>
  )
}
export default CycleableImage
