import React from 'react'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'

import styles from './Registration.module.scss'

export default function Registration({ className }) {
  return (
    <section className={cx(className)}>
      <h2>
        <FontAwesomeIcon icon={faLeaf} className="leafIcon" />
        Ilmoittautuminen
      </h2>
      <p>
        Pyydämme teitä ilmoittamaan osallistumisestanne juhliin 14.4.2019
        mennessä kutsun mukaisesti joko päiväosuuteen tai iltaosuuteen. Tarkista kutsusta, jos et ole varma.
      </p>
      <div className="grid-x grid-margin-x">
        <div className="small-6 cell">
          <Box
            text="Ilmoittaudun päiväjuhlaan"
            time="klo 13:30"
            url="https://goo.gl/forms/QfflD4Fe2X2CM0hj1"
          />
        </div>
        <div className="small-6 cell">
          <Box
            text="Ilmoittaudun iltajuhlaan"
            time="klo 19:00"
            url="https://goo.gl/forms/bmdy3n1yE4wIZlI42"
          />
        </div>
      </div>
    </section>
  )
}

function Box({ text, time, url }) {
  return (
    <a href={url} className={styles.box}>
      <div className={styles.boxText}>{text}</div>
      <div className={styles.boxTime}>{time}</div>
    </a>
  )
}
