import * as React from 'react'
import cx from 'classnames'
import styles from './EasterEggTip.module.scss'

export default function EasterEggTip({ className }) {
  return (
    <section className={cx(className, styles.text)}>
      Vinkki: Klikkaa meidän kuvaa ja katso, mitä tapahtuu
    </section>
  )
}
